<template>
  <div :class="`variants ${variant}`">
    <div class="flex items-center justify-between">
      <h3 class="font-weight: 500; font-size: 16px;">
        {{ title }}
      </h3>

      <RequestStatus :code="responseCode" />
    </div>

    <time class="text-xs text-gray-600">
      <i class="fas fa-clock-o" />
      <span>{{ time }}</span>
    </time>

    <slot name="contextData">
      <TimelineLogLayerDetailsContext :context="context" />
    </slot>

    <slot
      name="expandable"
      v-bind="{ expandableSlotClass: getClassFor('expandableSlot') }"
    >
      <div v-if="requestTraceId" :class="getClassFor('expandableSlot')">
        <!-- toggle -->
        <slot name="logsToggle">
          <button
            v-if="requestTraceId"
            :class="getClassFor('logsToggle')"
            @click="toggleExpandable('logs')"
          >
            {{ isExpanded('logs') ? 'Hide' : 'Show' }}

            Log

            <svg
              width="17"
              height="8"
              viewBox="0 0 17 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.3536 4.35355C16.5488 4.15829 16.5488 3.84171 16.3536 3.64645L13.1716 0.464466C12.9763 0.269204 12.6597 0.269204 12.4645 0.464466C12.2692 0.659728 12.2692 0.976311 12.4645 1.17157L15.2929 4L12.4645 6.82843C12.2692 7.02369 12.2692 7.34027 12.4645 7.53553C12.6597 7.7308 12.9763 7.7308 13.1716 7.53553L16.3536 4.35355ZM0 4.5H16V3.5H0V4.5Z"
                fill="#0954E4"
              />
            </svg>
            <loading
              :height="25"
              color="#1F2937"
              :active="isLogLoading"
              loader="bars"
              :class="`outline-none`"
              :is-full-page="false"
            />
          </button>

          <span
            v-else
            v-text="`No request trace!`"
            class="text-sm text-gray-500"
          />
        </slot>
        <!-- /toggle -->

        <!-- logsContent -->
        <slot name="logsContent">
          <AccordionTransition :expanded="isExpanded('logs')">
            <div class="w-full">
              <pre
                class="
                  log-code
                  flex flex-col
                  min-h-full
                  font-mono
                  text-xs text-gray-100
                  bg-black
                  md:text-sm
                "
                v-html="requestTrace"
              ></pre>
            </div>
          </AccordionTransition>
        </slot>
        <!-- logsContent -->
      </div>
      <div v-else class="my-1 text-sm text-gray-500">
        No request logs available.
      </div>

      <div
        v-if="iotCmdReqId && actionType"
        :class="getClassForIOT('expandableSlot')"
      >
        <!-- toggle -->
        <slot name="iotToggle">
          <button
            v-if="iotCmdReqId && actionType"
            :class="getClassForIOT('iotToggle')"
            @click="toggleExpandable('iot')"
          >
            {{ isExpanded('iot') ? 'Hide' : 'Show' }}

            IOT Log

            <svg
              width="17"
              height="8"
              viewBox="0 0 17 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.3536 4.35355C16.5488 4.15829 16.5488 3.84171 16.3536 3.64645L13.1716 0.464466C12.9763 0.269204 12.6597 0.269204 12.4645 0.464466C12.2692 0.659728 12.2692 0.976311 12.4645 1.17157L15.2929 4L12.4645 6.82843C12.2692 7.02369 12.2692 7.34027 12.4645 7.53553C12.6597 7.7308 12.9763 7.7308 13.1716 7.53553L16.3536 4.35355ZM0 4.5H16V3.5H0V4.5Z"
                fill="#0954E4"
              />
            </svg>
            <loading
              :height="25"
              color="#1F2937"
              :active="isIOTLogLoading"
              loader="bars"
              :class="`outline-none`"
              :is-full-page="false"
            />
          </button>

          <span
            v-else
            v-text="`No request trace!`"
            class="text-sm text-gray-500"
          />
        </slot>
        <!-- /toggle -->

        <!-- logsContent -->
        <slot name="logsContent">
          <AccordionTransition :expanded="isExpanded('iot')">
            <div class="max-w-6xl">
              <pre
                class="
                  log-code
                  flex flex-col
                  min-h-full
                  font-mono
                  text-xs text-gray-100
                  bg-black
                  md:text-sm
                "
                v-html="iotData"
              ></pre>
            </div>
          </AccordionTransition>
        </slot>
        <!-- logsContent -->
      </div>

      <div v-else class="my-1 text-sm text-gray-500">
        No iot logs available.
      </div>
    </slot>
  </div>
</template>
<script>
import { useEndpoints } from '@/composables'
export default {
  components: {
    AccordionTransition: () =>
      import('@/components/accordion/AccordionTransition.vue'),
    RequestStatus: () => import('@/components/badge/RequestStatus.vue'),
    TimelineLogLayerDetailsContext: () =>
      import('./TimelineLogLayerDetailsContext.vue'),
  },

  props: {
    title: {
      type: String,
      default: '',
    },

    time: {
      type: String,
      default: '',
    },
    requestTraceId: {
      type: String,
      default: '',
    },
    iotCmdReqId: {
      type: String,
      default: '',
    },
    actionType: {
      type: String,
      default: '',
    },

    responseCode: {
      type: Number,
      default: 0,
    },

    variant: {
      type: String,
      default: 'light',
    },

    context: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      expandables: {
        logs: false,
        iot: false,
      },
      requestTrace: '',
      iotData: '',
      isLogLoading: false,
      isIOTLogLoading: false,
    }
  },

  methods: {
    getClassFor(term = '') {
      const map = {
        expandableSlot: `mt-2 relative`,
        logsToggle: 'flex items-center text-blue-600 gap-2 text-sm',
      }

      return map[term] || `no-class-found-for:${term}`
    },
    getClassForIOT(term = '') {
      const map = {
        expandableSlot: `mt-2 relative`,
        iotToggle: 'flex items-center text-blue-600 gap-2 text-sm',
      }

      return map[term] || `no-class-found-for:${term}`
    },
    isExpanded(term) {
      return this.expandables[term]
    },
    toJSONSafe(rawData) {
      try {
        return JSON.parse(rawData)
      } catch {
        return rawData
      }
    },
    async toggleExpandable(term) {
      this.expandables[term] = !this.expandables[term]

      if (this.expandables['logs']) {
        this.isLogLoading = true
        const url = useEndpoints.trip.requestLog(this.requestTraceId)
        await this.$http
          .get(url)
          .then((res) => {
            console.log('fetchReqLogs res = ', res)
            const trace = res.data
            if (!trace) {
              this.requestTrace = ''
            } else {
              const reqTitle =
                '<div>Request Logs</div><div>==============</div>'
              const resTitle =
                '<br/><br/><div>Response Logs</div><div>==============</div>'

              const reqLogs = {
                id: trace?.id,
                ip: trace?.ip,
                user_agent: trace?.user_agent,
                host: trace?.host,
                method: trace?.method,
                path: trace?.path,
                request_body: this.toJSONSafe(trace?.request_body),
              }

              let response = trace?.response
              const resLogs = {
                language: trace?.language,
                response_code: trace?.response_code,
                response_time: trace?.exec_time,
                response_body: this.toJSONSafe(response),
              }
              this.requestTrace = `<span class="block overflow-auto">${reqTitle}${JSON.stringify(
                reqLogs,
                null,
                4
              )}<br/>${resTitle}${JSON.stringify(resLogs, null, 4)}</span>`
            }
          })
          .catch((err) => {
            // console.error('fetchReqLogs err = ', err, err.response.data)
            console.error('fetchReqLogs err = ', { err })
          })
          .finally(() => (this.isLogLoading = false))
      }
      if (this.expandables['iot']) {
        this.isIOTLogLoading = true
        const url = useEndpoints.trip.iotLog(this.iotCmdReqId, this.actionType)
        await this.$http
          .get(url)
          .then((res) => {
            console.log('fetchReqLogs res = ', res)
            const reqTitle = '<div>Request Logs</div><div>==============</div>'
            const resTitle =
              '<br/><br/><div>Response Logs</div><div>==============</div>'
            const iotLogs = res.data

            let parsed_data = iotLogs?.data?.parsed_data
            if (typeof parsed_data == 'string') {
              let back_slash = '\\'
              parsed_data = parsed_data.replace(`/${back_slash}/g`, '')
              iotLogs.data.parsed_data = JSON.parse(parsed_data)
            }
            this.iotData = `${
              iotLogs.data_type === 'response' ? resTitle : reqTitle
            }<span class="block overflow-auto">${JSON.stringify(
              iotLogs.data,
              null,
              4
            )}</span>`
          })
          .catch((err) => {
            // console.error('fetchReqLogs err = ', err, err.response.data)
            console.error('fetchReqLogs err = ', { err })
          })
          .finally(() => (this.isIOTLogLoading = false))
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.variants {
  @apply max-w-2xl p-4 mt-3;
  border-radius: 7px;

  &.light {
    background: #fff;
    border: 1px solid #d5d5d5;
    border-radius: 8px;
  }

  &.secondary {
    background: #f1f1f1;
    border: 1px solid #d5d5d5;
    border-radius: 8px;
  }

  &.warning {
    background: #f9f0d7;
    border: 1px solid #f2c816;
    border-radius: 8px;
  }
}

.logs-content {
  color: #fff;
  padding: 5px 8px;
  background: #000000;
  border: 1px solid #ffffff;
  border-radius: 7px;
  overflow: auto !important;
}
.map-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  color: #ffffff;
  border-radius: 5px;
  background-color: #ff598a;
  cursor: pointer;
  width: 23px;
  height: 23px;
  background-color: #12ceda;
}
</style>
