<template>
  <tbody class="fst-body">
    <tr class="align-top fst-row fst-row-white" v-if="getFullMode">
      <!-- <div
      class="connector"
      :class="isConnectedWith('before|duo') ? 'connected' : 'not-connected'"
    /> -->

      <td class="fst-row-item">
        <div class="section-year">
          <p class="year">
            <span v-text="time" class="date-time" />
            <br />
            <span v-text="date" class="date-date" style="" />
            <br />
          </p>
        </div>
      </td>

      <td class="fst-row-item relative">
        <div
          class="absolute connected-up border-l-2 border-gray-300"
          v-if="isConnectedWith('before|duo')"
        />
        <div
          class="absolute connected-down border-l-2 border-gray-300"
          v-if="isConnectedWith('after|duo')"
        />
        <div class="relative flex flex-col">
          <div
            class="top-0 left-0 flex items-center justify-center bg-gray-200 rounded-full"
            :class="iconSrc ? 'w-8 h-8' : 'w-10 h-10'"
          >
            <OtoIcon
              v-if="otoIcon"
              :action-name="otoIcon"
              :styles="{ marginTop: '0.1rem', marginLeft: '-0.30rem' }"
            />

            <img v-else-if="iconSrc" :src="iconSrc" />

            <i v-else :class="iconClass" />
          </div>
        </div>
      </td>

      <td class="fst-row-item">
        <div :class="getClassFor('defaultSlot')">
          <slot
            name="default"
            v-bind="{ defaultSlotClass: getClassFor('defaultSlot') }"
          >
            <slot
              name="visible"
              v-bind="{ visibleSlotClass: getClassFor('visibleSlot') }"
            >
              Visible slot
            </slot>

            <slot
              name="expandable"
              v-bind="{ expandableSlotClass: getClassFor('expandableSlot') }"
            >
              <div :class="getClassFor('expandableSlot')">
                <!-- toggle -->
                <slot name="detailsToggle">
                  <button
                    :class="getClassFor('detailsToggle')"
                    @click="toggleExpandable('details')"
                  >
                    {{ isExpanded('details') ? 'Hide' : 'Show' }}

                    Details

                    <svg
                      width="17"
                      height="8"
                      viewBox="0 0 17 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.3536 4.35355C16.5488 4.15829 16.5488 3.84171 16.3536 3.64645L13.1716 0.464466C12.9763 0.269204 12.6597 0.269204 12.4645 0.464466C12.2692 0.659728 12.2692 0.976311 12.4645 1.17157L15.2929 4L12.4645 6.82843C12.2692 7.02369 12.2692 7.34027 12.4645 7.53553C12.6597 7.7308 12.9763 7.7308 13.1716 7.53553L16.3536 4.35355ZM0 4.5H16V3.5H0V4.5Z"
                        fill="#0954E4"
                      />
                    </svg>
                  </button>
                </slot>
                <!-- /toggle -->

                <AccordionTransition :expanded="isExpanded('details')">
                  <slot name="detailsContent" />
                </AccordionTransition>
              </div>
            </slot>
          </slot>
        </div>
      </td>

      <td class="fst-row-item text-center" v-if="timelineType === 'trip'">
        <RequestStatus :code="responseCode" />
      </td>
      <td class="fst-row-item text-center" v-if="timelineType === 'trip'">
        <BatteryStatus :level="powerLevel" />
      </td>
      <td class="fst-row-item text-center" v-if="timelineType === 'trip'">
        <div class="map-icon-wrapper">
          <div class="map-icon" @click="$emit('click-map', coords)">
            <i class="fas fa-map" />
          </div>
        </div>
      </td>
      <td class="fst-row-item text-center" v-if="timelineType === 'trip'">
        {{ getRiderBalanceStr }}
      </td>

      <td class="fst-row-item text-center" v-if="timelineType === 'rider'">
        <RequestStatus :code="responseCode" />
      </td>
      <td class="fst-row-item text-center" v-if="timelineType === 'rider'">
        {{ getRiderBalanceStr }}
      </td>
      <td class="fst-row-item text-center" v-if="timelineType === 'rider'">
        <div class="map-icon-wrapper">
          <div class="map-icon" @click="$emit('click-map', coords)">
            <i class="fas fa-map" />
          </div>
        </div>
      </td>
      <td class="fst-row-item text-center" v-if="timelineType === 'rider'">
        <RouterLink
          v-if="tripId !== ''"
          :to="{
            name: 'ViewTrip',
            params: { id: tripId },
          }"
          target="_blank"
          class="text-blue-600 capitalize"
        >
          #{{ tripId.substr(tripId.length - 5) }}
        </RouterLink>
        <span v-if="tripId === ''" class="">--</span>
      </td>

      <td class="fst-row-item text-center" v-if="timelineType === 'vehicle'">
        <RequestStatus :code="responseCode" />
      </td>
      <td class="fst-row-item text-center" v-if="timelineType === 'vehicle'">
        <BatteryStatus :level="powerLevel" />
      </td>
      <td class="fst-row-item text-center" v-if="timelineType === 'vehicle'">
        <div class="map-icon-wrapper">
          <div class="map-icon" @click="$emit('click-map', coords)">
            <i class="fas fa-map" />
          </div>
        </div>
      </td>
      <td class="fst-row-item text-center" v-if="timelineType === 'vehicle'">
        <RouterLink
          v-if="actor"
          :to="{
            name: `${
              actor.role_name == '--'
                ? 'ViewRiderUserProfile'
                : 'ViewOrganizationUserProfile'
            }`,
            params: { id: actor.id },
          }"
          target="_blank"
          class="text-blue-600 capitalize"
        >
          {{ actor.full_name }}
        </RouterLink>
        <span v-if="actor" class="text-sm text-gray-600">
          ({{ actor.role_name }})
        </span>
        <span v-if="!actor" class="">--</span>
      </td>

      <td class="fst-row-item text-center" v-if="timelineType === 'rental'">
        <RequestStatus :code="responseCode" />
      </td>
      <td class="fst-row-item text-center" v-if="timelineType === 'rental'">
        {{ getRiderBalanceStr }}
      </td>
      <td class="fst-row-item text-center" v-if="timelineType === 'rental'">
        <BatteryStatus :level="powerLevel" />
      </td>
      <td class="fst-row-item text-center" v-if="timelineType === 'rental'">
        <span>{{ timeAgo }}</span>
      </td>

      <!-- report log-->
      <td class="fst-row-item text-center" v-if="timelineType === 'report'">
        <RequestStatus :code="responseCode" />
      </td>
      <td
        class="fst-row-item text-center"
        v-if="timelineType === 'report' && isPointsShow"
      >
        <div
          :key="`point-item-${pointIndex}`"
          v-for="(pointDetails, pointIndex) in pointsHistory"
        >
          <RouterLink
            :to="{
              name: 'ViewRiderUserProfile',
              params: { id: pointDetails.id },
            }"
            target="_blank"
            class="text-blue-600 capitalize"
          >
            {{ pointDetails.name }}
          </RouterLink>
          <span class="text-sm text-gray-600">
            ({{ pointDetails.point }})
          </span>
        </div>
      </td>
      <td class="fst-row-item text-center" v-if="timelineType === 'report'">
        {{ remarks }}
      </td>
      <td class="fst-row-item text-center" v-if="timelineType === 'report'">
        <RouterLink
          v-if="actor"
          :to="{
            name: 'ViewOrganizationUserProfile',
            params: { id: actor.id },
          }"
          target="_blank"
          class="text-blue-600 capitalize"
        >
          {{ actor.full_name }}
        </RouterLink>
        <span v-if="actor" class="text-sm text-gray-600">
          ({{ actor.role_name }})
        </span>
        <span v-if="!actor" class="">--</span>
      </td>
      <!-- <div
      class="connector"
      :class="isConnectedWith('after|duo') ? 'connected' : ''"
    /> -->
    </tr>
    <template v-if="getResponsiveMode">
      <tr class="align-top fst-row fst-row-white">
        <td class="fst-row-item">
          <div class="col-span-1 focus:text-gray-400" @click="toggle(logIndex)">
            <i
              class="fas fa-minus-circle"
              style="color:#d90a20;"
              v-if="opened.includes(logIndex)"
            ></i>
            <i class="fas fa-plus-circle" v-else></i>
          </div>
        </td>
        <td class="fst-row-item">
          <div class="section-year">
            <p class="year">
              <span v-text="time" class="date-time" />
              <br />
              <span v-text="date" class="date-date" style="" />
              <br />
            </p>
          </div>
        </td>
        <td class="fst-row-item">
          <div :class="getClassFor('defaultSlot')">
            <slot
              name="default"
              v-bind="{ defaultSlotClass: getClassFor('defaultSlot') }"
            >
              <slot
                name="visible"
                v-bind="{ visibleSlotClass: getClassFor('visibleSlot') }"
              >
                Visible slot
              </slot>

              <slot
                name="expandable"
                v-bind="{ expandableSlotClass: getClassFor('expandableSlot') }"
              >
                <div :class="getClassFor('expandableSlot')">
                  <!-- toggle -->
                  <slot name="detailsToggle">
                    <button
                      :class="getClassFor('detailsToggle')"
                      @click="toggleExpandable('details')"
                    >
                      {{ isExpanded('details') ? 'Hide' : 'Show' }}

                      Details

                      <svg
                        width="17"
                        height="8"
                        viewBox="0 0 17 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16.3536 4.35355C16.5488 4.15829 16.5488 3.84171 16.3536 3.64645L13.1716 0.464466C12.9763 0.269204 12.6597 0.269204 12.4645 0.464466C12.2692 0.659728 12.2692 0.976311 12.4645 1.17157L15.2929 4L12.4645 6.82843C12.2692 7.02369 12.2692 7.34027 12.4645 7.53553C12.6597 7.7308 12.9763 7.7308 13.1716 7.53553L16.3536 4.35355ZM0 4.5H16V3.5H0V4.5Z"
                          fill="#0954E4"
                        />
                      </svg>
                    </button>
                  </slot>
                  <!-- /toggle -->

                  <AccordionTransition :expanded="isExpanded('details')">
                    <slot name="detailsContent" />
                  </AccordionTransition>
                </div>
              </slot>
            </slot>
          </div>
        </td>
      </tr>
      <tr
        class="align-top fst-row fst-row-white"
        v-if="opened.includes(logIndex)"
      >
        <td colspan="10">
          <div class="grid grid-cols-10 items-center w-full expand-item">
            <div class="col-span-5 left-text">Event Type</div>
            <div class="col-span-5 right-text">
              <div
                class="top-0 left-0 flex items-center justify-center bg-gray-200 rounded-full"
                :class="iconSrc ? 'w-8 h-8' : 'w-10 h-10'"
              >
                <OtoIcon
                  v-if="otoIcon"
                  :action-name="otoIcon"
                  :styles="{ marginTop: '0.1rem', marginLeft: '-0.30rem' }"
                />

                <img v-else-if="iconSrc" :src="iconSrc" />

                <i v-else :class="iconClass" />
              </div>
            </div>
          </div>
          <div
            v-if="timelineType === 'trip'"
            class="grid grid-cols-10 items-center w-full expand-item"
          >
            <div class="col-span-5 left-text">Response</div>
            <div class="col-span-5 right-text">
              <RequestStatus :code="responseCode" />
            </div>
          </div>
          <div
            v-if="timelineType === 'trip'"
            class="grid grid-cols-10 items-center w-full expand-item"
          >
            <div class="col-span-5 left-text">Battery</div>
            <div class="col-span-5 right-text">
              <BatteryStatus :level="powerLevel" />
            </div>
          </div>
          <div
            v-if="timelineType === 'trip'"
            class="grid grid-cols-10 items-center w-full expand-item"
          >
            <div class="col-span-5 left-text">Location</div>
            <div class="col-span-5 right-text">
              <div class="map-icon-wrapper-res">
                <div class="map-icon" @click="$emit('click-map', coords)">
                  <i class="fas fa-map" />
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="timelineType === 'trip'"
            class="grid grid-cols-10 items-center w-full expand-item"
          >
            <div class="col-span-5 left-text">Balance</div>
            <div class="col-span-5 right-text">
              {{ getRiderBalanceStr }}
            </div>
          </div>

          <div
            v-if="timelineType === 'rider'"
            class="grid grid-cols-10 items-center w-full expand-item"
          >
            <div class="col-span-5 left-text">Response</div>
            <div class="col-span-5 right-text">
              <RequestStatus :code="responseCode" />
            </div>
          </div>
          <div
            v-if="timelineType === 'rider'"
            class="grid grid-cols-10 items-center w-full expand-item"
          >
            <div class="col-span-5 left-text">Balance</div>
            <div class="col-span-5 right-text">
              {{ getRiderBalanceStr }}
            </div>
          </div>
          <div
            v-if="timelineType === 'rider'"
            class="grid grid-cols-10 items-center w-full expand-item"
          >
            <div class="col-span-5 left-text">Location</div>
            <div class="col-span-5 right-text">
              <div class="map-icon-wrapper-res">
                <div class="map-icon" @click="$emit('click-map', coords)">
                  <i class="fas fa-map" />
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="timelineType === 'rider'"
            class="grid grid-cols-10 items-center w-full expand-item"
          >
            <div class="col-span-5 left-text">Trip ID</div>
            <div class="col-span-5 right-text">
              <RouterLink
                v-if="tripId !== ''"
                :to="{
                  name: 'ViewTrip',
                  params: { id: tripId },
                }"
                target="_blank"
                class="text-blue-600 capitalize"
              >
                #{{ tripId.substr(tripId.length - 5) }}
              </RouterLink>
              <span v-if="tripId === ''" class="">--</span>
            </div>
          </div>
          <div
            v-if="timelineType === 'vehicle'"
            class="grid grid-cols-10 items-center w-full expand-item"
          >
            <div class="col-span-5 left-text">Response</div>
            <div class="col-span-5 right-text">
              <RequestStatus :code="responseCode" />
            </div>
          </div>
          <div
            v-if="timelineType === 'vehicle'"
            class="grid grid-cols-10 items-center w-full expand-item"
          >
            <div class="col-span-5 left-text">Battery</div>
            <div class="col-span-5 right-text">
              <BatteryStatus :level="powerLevel" />
            </div>
          </div>
          <div
            v-if="timelineType === 'vehicle'"
            class="grid grid-cols-10 items-center w-full expand-item"
          >
            <div class="col-span-5 left-text">Location</div>
            <div class="col-span-5 right-text">
              <div class="map-icon-wrapper-res">
                <div class="map-icon" @click="$emit('click-map', coords)">
                  <i class="fas fa-map" />
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="timelineType === 'vehicle'"
            class="grid grid-cols-10 items-center w-full expand-item"
          >
            <div class="col-span-5 left-text">Actor</div>
            <div class="col-span-5 right-text">
              <RouterLink
                v-if="actor"
                :to="{
                  name: 'ViewOrganizationUserProfile',
                  params: { id: actor.id },
                }"
                target="_blank"
                class="text-blue-600 capitalize"
              >
                {{ actor.full_name }}
              </RouterLink>
              <span v-if="actor" class="text-sm text-gray-600">
                ({{ actor.role_name }})
              </span>
              <span v-if="!actor" class="">--</span>
            </div>
          </div>
          <div
            v-if="timelineType === 'rental'"
            class="grid grid-cols-10 items-center w-full expand-item"
          >
            <div class="col-span-5 left-text">Status</div>
            <div class="col-span-5 right-text">
              <RequestStatus :code="responseCode" />
            </div>
          </div>
          <div
            v-if="timelineType === 'rental'"
            class="grid grid-cols-10 items-center w-full expand-item"
          >
            <div class="col-span-5 left-text">Balance</div>
            <div class="col-span-5 right-text">
              {{ getRiderBalanceStr }}
            </div>
          </div>
          <div
            v-if="timelineType === 'rental'"
            class="grid grid-cols-10 items-center w-full expand-item"
          >
            <div class="col-span-5 left-text">Battery</div>
            <div class="col-span-5 right-text">
              <BatteryStatus :level="powerLevel" />
            </div>
          </div>
          <div
            v-if="timelineType === 'rental'"
            class="grid grid-cols-10 items-center w-full expand-item"
          >
            <div class="col-span-5 left-text">Time Ago</div>
            <div class="col-span-5 right-text">
              <span>{{ timeAgo }}</span>
            </div>
          </div>
          <div
            v-if="timelineType === 'report'"
            class="grid grid-cols-10 items-center w-full expand-item"
          >
            <div class="col-span-5 left-text">Response</div>
            <div class="col-span-5 right-text">
              <RequestStatus :code="responseCode" />
            </div>
          </div>
          <div
            v-if="timelineType === 'report' && isPointsShow"
            class="grid grid-cols-10 items-center w-full expand-item"
          >
            <div class="col-span-5 left-text">Points</div>
            <div class="col-span-5 right-text">
              <div
                :key="`point-item-${pointIndex}`"
                v-for="(pointDetails, pointIndex) in pointsHistory"
              >
                <RouterLink
                  :to="{
                    name: 'ViewRiderUserProfile',
                    params: { id: pointDetails.id },
                  }"
                  target="_blank"
                  class="text-blue-600 capitalize"
                >
                  {{ pointDetails.name }}
                </RouterLink>
                <span class="text-sm text-gray-600">
                  ({{ pointDetails.point }})
                </span>
              </div>
            </div>
          </div>
          <div
            v-if="timelineType === 'report'"
            class="grid grid-cols-10 items-center w-full expand-item"
          >
            <div class="col-span-5 left-text">Remarks</div>
            <div class="col-span-5 right-text">
              {{ remarks }}
            </div>
          </div>
          <div
            v-if="timelineType === 'report'"
            class="grid grid-cols-10 items-center w-full expand-item"
          >
            <div class="col-span-5 left-text">Actor</div>
            <div class="col-span-5 right-text">
              <RouterLink
                v-if="actor"
                :to="{
                  name: 'ViewOrganizationUserProfile',
                  params: { id: actor.id },
                }"
                target="_blank"
                class="text-blue-600 capitalize"
              >
                {{ actor.full_name }}
              </RouterLink>
              <span v-if="actor" class="text-sm text-gray-600">
                ({{ actor.role_name }})
              </span>
              <span v-if="!actor" class="">--</span>
            </div>
          </div>
        </td>
      </tr>
    </template>
  </tbody>
</template>

<script>
import AccordionTransition from '@/components/accordion/AccordionTransition.vue'
import OtoIcon from '@/components/ui/OtoIcon.vue'

export const variantOptions = [
  'none',
  'light',
  'secondary',
  'success',
  'warning',
  'danger',
]
export const connectedWithOptions = ['none', 'before', 'after', 'duo']

export default {
  components: {
    BatteryStatus: () => import('@/components/badge/BatteryStatus.vue'),
    RequestStatus: () => import('@/components/badge/RequestStatus.vue'),

    AccordionTransition,
    OtoIcon,
  },
  name: 'TimelineLogItem',

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    logIndex: {
      type: Number,
      default: 0,
    },

    time: {
      type: String,
      default: '',
    },

    date: {
      type: String,
      default: '11',
    },

    timeAgo: {
      type: String,
      default: '--',
    },

    route: {
      type: Object,
      default: () => ({ text: '', to: '#' }),
    },

    variant: {
      type: String,
      default: 'none',
      validator: (v) => variantOptions.includes(v),
    },

    // oto-icon is the first priority
    otoIcon: {
      type: String,
      default: '',
    },

    // src ir second priority
    iconSrc: {
      type: null,
      default: null,
    },

    iconClass: {
      type: String,
      default: 'fa fa-check-circle text-green-600',
    },

    // timeline line connector
    connectedWith: {
      type: String,
      default: 'none',
      validator: (v) => connectedWithOptions.includes(v),
    },

    timelineType: {
      type: String,
      required: false,
      default: 'generic',
    },

    responseCode: {
      type: Number,
      default: 0,
    },

    coords: {
      type: String,
      default: '0,0',
    },

    userBalance: {
      type: [String, Number],
      default: '0.0',
    },

    userBalanceCurrency: {
      type: String,
      default: '$',
    },

    tripId: {
      type: String,
      default: '',
    },

    powerLevel: {
      type: Number,
      default: 0,
    },

    actor: {
      type: Object,
      default: null,
    },
    remarks: {
      type: String,
      default: '',
    },
    pointsHistory: {
      type: Array,
      default: () => [],
    },
    isPointsShow: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      expandables: {
        details: false,
        request: false,
      },
      responseScreenStatus: null,
      opened: [],
    }
  },

  computed: {
    getRiderBalanceStr() {
      return `${this.userBalanceCurrency} ${this.userBalance}`
    },
    getFullMode() {
      return window.matchMedia('(min-width: 931px)').matches
    },
    getResponsiveMode() {
      return window.matchMedia('(max-width: 930px)').matches
    },
  },

  methods: {
    toggle(id) {
      const index = this.opened.indexOf(id)
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },
    isConnectedWith(term = '') {
      const pipes = term.split('|')
      const connectedWith = this.connectedWith.toLowerCase()
      const isConnectedWith = pipes.includes(connectedWith.toLowerCase())
      // console.log('connectedWith', connectedWith, pipes, isConnectedWith)
      return isConnectedWith
    },

    isExpanded(term) {
      return this.expandables[term]
    },
    toggleExpandable(term) {
      this.expandables[term] = !this.expandables[term]
    },

    getClassFor(term = '') {
      const map = {
        defaultSlot: `defaultSlot ${this.variant}`,

        expandableSlot: `mt-2`,

        detailsToggle: 'flex items-center text-blue-600 gap-2 text-sm',
      }

      return map[term] || `no-class-found-for:${term}`
    },
  },
}
</script>

<style lang="scss" scoped>
.defaultSlot {
  // @apply px-4 py-4;

  &.success {
    background: #def5e6;
    border: 1px solid #15893c;
    border-radius: 8px;

    @apply p-4;
  }

  &.warning {
    background: #f9f0d7;
    border: 1px solid #f2c816;
    border-radius: 8px;

    @apply p-4;
  }

  &.light {
    background: #fff;
    // border: 1px solid #d5d5d5;
    border: 1px solid #fff;
    border-radius: 8px;

    // @apply p-4;
  }

  &.secondary {
    background: #f5f5f5;
    border: 1px solid #d5d5d5;
    border-radius: 8px;

    @apply p-4;
  }

  &.danger {
    // background: #ffdbd2;
    border: 1px solid #ff0000;
    border-radius: 8px;

    @apply p-4;
  }
}

.timeline {
  position: relative;

  .wrapper {
    display: grid;
    grid-template-columns: 100px 25px 1fr;
    column-gap: 15px;
  }

  .section-year {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    padding: 15px;
    font-weight: bold;
    font-size: 12px;
    .year {
      margin: 0;
      .date-time {
        color: #2e2e39;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 5px;
      }
      .date-date {
        color: #909099;
        font-size: 13px;
        font-weight: 400;
      }
    }
  }

  &.unique-timeline {
    margin-bottom: 0;
  }
}

.connector {
  margin-left: 115px;
  @apply relative flex flex-col;

  &.connected {
    @apply h-10 -mb-4 border-l-2 border-gray-300;
  }
  &.not-connected {
    @apply h-10 -mb-4 border-l-2 border-white;
  }
}

.fst .fst-body .fst-row {
  border-bottom-width: 0px !important;
}

.map-icon-wrapper {
  display: flex;
  width: 100%;

  @apply justify-center;
}
.map-icon-wrapper-res {
  display: flex;
  width: 100%;

  @apply justify-start;
}

.map-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  color: #ffffff;
  border-radius: 5px;
  background-color: #ff598a;
  cursor: pointer;
  width: 23px;
  height: 23px;
  background-color: #12ceda;
}

.connected-down {
  left: 20px;
  bottom: 0;
  top: 50%;
}

.connected-up {
  left: 20px;
  top: -1px;
  bottom: 50%;
}

.left-text {
  /* font-family: Roboto; */
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3a4048;
}
.right-text {
  /* font-family: Roboto; */
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d75;
  //text-align: right;
}
</style>

<style lang="scss" scoped>
@import '@/components/fs-table/$fs-table.scss';
</style>
